import Echo from 'laravel-echo';

export default class Pusher{
    constructor(){
        this.echo = new Echo({
            broadcaster: 'pusher',
            key: process.env.PUSHER_APP_KEY,
            cluster: process.env.PUSHER_APP_CLUSTER,
            forceTLS: true,
            encrypted: true
        });
    }

    listenChannel(user_id){
        this.echo.private(`session.${user_id}`)
            .listen('SessionNotification', (e) => {
                console.log(e);
        });
    }
};
